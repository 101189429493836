





























































import { Component, Prop } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import Util from '@/utils/Util';
import { internet } from '@/utils/Internet';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import DataTable from '@/components/util/DataTable.vue';
import { IHabilidades } from '@/model/habilidades/IHabilidades';
import { utils, writeFile } from 'xlsx';
    @Component({
        name: 'MainMalasPalabras',
        components: {
                AppContainerBase,
                DataTable
        }})
    export default class MainMalasPalabras extends MixinMain {
        public title = this.$t("HabilidadesBlandas.title");
        public countElementPage = this.$t("DataTable.countElementPage");
        public elementPerPage = this.$t("DataTable.elementPerPage");
		public isLoading = false;
        //Tabla
        public rows: Array<IDataTable> = [];
        public header: Array<IHeaderTable<IDataTable>> = [];
		public searchText = this.$t("DataTable.searchText");
		public search = '';
        
        mounted(): void {
            this.getData();
		}
        
        private getData(): void {
			this.isLoading = true;
			const request_1 = internet
				.newRequest()
				.get(`habilidades/getHabilidades`);
            
            Util.waitForPromises([request_1])
				.then((responses) => {
					const data = responses[0].data;
					this.isLoading =false;
                    console.log(data);
					const dataTable: Array<IDataTable> = [];
					//console.log(this.getFilter);
					for (let item of data) {
						console.log(item);

						const row: IDataTable = {};
						row['agente'] = item.agente;
						//row['cant'] = item.num_evaluaciones;
						//row['amab'] = item.evaluaciones.amabilidad;
                        row['amab'] = ((item.evaluaciones.amabilidad/item.num_evaluaciones)*100).toFixed(1) +'%';
						row['sere'] = ((item.evaluaciones.serenidad/item.num_evaluaciones)*100).toFixed(1) +'%';
						row['flui'] = ((item.evaluaciones.fluidez/item.num_evaluaciones)*100).toFixed(1) +'%';
                        row['aten'] = ((item.evaluaciones.atencion/item.num_evaluaciones)*100).toFixed(1) +'%';
                        row['modu'] = ((item.evaluaciones.modulacion/item.num_evaluaciones)*100).toFixed(1) +'%';
						//console.log(JSON.stringify(item.tunning))
						dataTable.push(row);
					}
					const header: Array<IHeaderTable<
						IDataTable
					>> = Object.keys(dataTable[0]).map(
						(
							key: string
						): IHeaderTable<IDataTable> => {
							let text = key;
							switch (key) {
								case 'agente':
									text = this.$t("HabilidadesBlandas.agente") as string;
									break;
								//case 'cant':
									//text = this.$t("HabilidadesBlandas.cantidad") as string;
									//break;
								case 'amab':
									text = this.$t("HabilidadesBlandas.amabilidad") as string;
									break;
								case 'sere':
									text = this.$t("HabilidadesBlandas.serenidad") as string;
									break;
								case 'flui':
									text = this.$t("HabilidadesBlandas.fluidez") as string;
									break;
                                case 'aten':
									text = this.$t("HabilidadesBlandas.atencion") as string;
									break;
                                case 'modu':
									text = this.$t("HabilidadesBlandas.modulacion") as string;
									break;
							}
							return {
								text,
								value: key,
							};
						}
					) as Array<IHeaderTable<IDataTable>>;
					this.rows = dataTable;
					this.header = header;
					
				})
				.catch(console.log)
				.finally(() => {
					//this.isLoading = false;
				});
        }
            

        public getTextCountElementPerPage(
			to: number,
			end: number
		): string {
			let text = Util.replaceTextWith(
				this.countElementPage as string,
				'$1',
				to.toString()
			);
			text = Util.replaceTextWith(
				text,
				'$2',
				end.toString()
			);
			return text;
		}

		public descargarExcel(){
		const request = internet 
		.newRequest()
		.get("exportable/habilidades")
		Util.waitForPromises([request])
		.then((response) => {
			console.log(response[0])
			const data_1 = response[0].data as Array<Record<string, string>>
			const order_1 = [
				'nombre_audio',
				'agente',
				'modulacion',
				'atencion',
				'fluidez',
				'serenidad',
				'amabilidad'
			];
			const result_1: Array<Record<
				string,
				string
			>> = data_1.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of order_1) {
						exit[
							Util.replaceTextWith(
								order,
								'_',
								' '
							).toUpperCase()
						] = obj[order.toLowerCase()];
					}
					return exit;
				}
			);
			const audio = utils.json_to_sheet(result_1);
			const book = utils.book_new();
			utils.book_append_sheet(
				book,
				audio,
				'Evaluación Habilidades'
			);
			writeFile(book, 'Repositorio_habilidades.xlsx');
		}
		)
	}

	public listenClickRow(payload: IDataTable) {
			console.log("payload", payload)
			const agent = payload.agente
			
			this.$router.push({
				name: 'MainDetailHabilidadesBlandas',
				params: {
					agente:agent,
				},
			});
		}

        }
